import React from 'react'
import heroLogo from './assets/heroLogo-removebg-preview.png';
import { Link } from "react-router-dom";

function AboutUs() {
    
  return (
    <>
      <section className='mb-5' id='about-us' style={{
        maxWidth:"100vw"
      }}>
        <div className='container'>
        <div className="row">
          <div className='col-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-center order-2 order-sm-2 order-md-1 order-lg-1'>
            <div>
              <h2 style={{
                fontFamily: "'Courgette', cursive"
              }}>Welcome to WoodNWiings Creations...</h2>
              <p className='text-muted'>where we carve dreams into reality through the richness of wood. Our passion for wooden interior work transcends boundaries, offering you a symphony of textures, tones, and craftsmanship. From handcrafted furniture to stunning wood accents, we meticulously design each element to enhance your living or working space. Experience the fusion of tradition and innovation, as we bring the timeless allure of wood to the heart of your interiors.</p>
              <Link className="btn btn-block btn-danger" style={{
                marginRight : "1.5rem",
                borderRadius : "0px"
              }} to="tel:9695029136">Call Now</Link>
              <button className="btn btn-outline-danger" style={{
                borderRadius : "0px"
              }}>Email us</button>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-6 col-lg-6 text-center order-1 order-sm-1 order-md-2 order-lg-2'>
            <img alt="logo" src={heroLogo} style={{
              height:"auto",
              width:"80%",
              objectFit : "contain"
            }} />
          </div>
        </div>
        </div>
      </section>
    </>
  )
}

export default AboutUs