import React from 'react'

function Footer() {
  return (
    <>
        <section id='footer'>
            <div className='container-fluid bg-danger text-white text-center' style={{
                paddingTop : "5px",
                paddingBottom : "5px"
            }}>&copy; WoodNWiings Creations { new Date()?.getFullYear() } - { new Date()?.getFullYear() + 1 } </div>
        </section>
    </>
  )
}

export default Footer