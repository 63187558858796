export default function Contactus(){
    return (
        <>
            <section id="contact-us" className="mt-3 d-flex align-items-center" style={{
                height:'100vh'
            }}>
                <div className="container">
                    <h1 className="text-center text-uppercase">Contact Us</h1>
                    <p className="text-center text-muted mb-5" style={{
                        fontFamily: "'Courgette', cursive"
                    }}>Questions about transforming your space? Reach out and let's design your dream together!</p>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-lg-6">
                            <div className="form-group">
                                <label className="text-muted" for="name">Name</label>
                                <input style={{
                                    height : '3.2rem',
                                    borderRadius : "0px"
                                }} type="text" id="name" className="form-control" />
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-lg-6">
                            <div className="form-group">
                                <label className="text-muted" for="name">Contact Number</label>
                                <input style={{
                                    height : '3.2rem',
                                    borderRadius : "0px"
                                }} type="text" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 col-sm-12 col-lg-12">
                            <div className="form-group">
                                <label className="text-muted" for="name">Message</label>
                                <textarea style={{
                                    height : '12rem',
                                    borderRadius : "0px"
                                }} className="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <button type="button" style={{
                            backgroundColor : "#F43F5E", 
                            color:"#ffffff",
                            borderRadius : "0px"
                            }} className="btn btn-lg btn-block mt-3 text-center">Get a Free Quote</button>
                    </div>
                </div>
            </section>
        </>
    );
}