import React from 'react'
import Header from './Header'
import AboutUs from './AboutUs'
import Contactus from './Contactus'
import FaqSection from './FaqSection'
import Footer from './Footer'

function Home() {
  return (
    <>
        <Header />
        <AboutUs />
        <FaqSection />
        <Contactus />
        <Footer />
    </>
  )
}

export default Home